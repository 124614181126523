<template>
    <div class="email-campaign">
        <div class="email-next">
            <div class="list-btns">
                <ul>
                    <li><a v-on:click="step=1" class="step1" v-bind:class="step == 1 ? 'active':''" href="#">Basic Info</a></li>
                    <li><a v-on:click="step=2" class="step2" v-bind:class="step == 2 ? 'active':''" href="#">Services</a></li>
                    <li><a v-on:click="step=3" class="step3" v-bind:class="step == 3 ? 'active':''" href="#">Weekly Schedule</a></li>
                    <span class="bar"></span>
                </ul>
            </div>
        </div>
        <form action="">
            <div class="email-step1" v-show="step == 1 ? 'active':''">
                <div class="step1-area">
                    <div class="email-deatils">
                        <h3>Basic Info</h3>
                        
                        <!-- <div class="form-row mb-4">
                            <div class="form-group col-sm-12 col-md-8">
                                <label>Email <span style="font-size:11px">(Find the email of the professional before you proceed)</span></label>
                                <div class="d-flex email-search">
                                    <input v-model="employee_.email" :class="[v$.employee_.email.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Enter email">
                                    <a v-on:click.prevent="searchUser" href="#" class="rem-btn" >Search</a>
                                </div>
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >First name</label>
                                    <input v-model="employee_.first_name" :class="[v$.employee_.first_name.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="First name" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label >Last name</label>
                                    <input v-model="employee_.last_name" :class="[v$.employee_.last_name.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="Last name" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label>Email </label>
                                <div class="d-flex email-search">
                                    <input v-model="employee_.email" :class="[v$.employee_.email.$error ? 'border-danger': '']"  :disabled="isDisable" type="email" class="form-control" placeholder="Enter email">
                                </div>
                            </div>
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >Phone</label>
                                    <vue-tel-input v-if="employee_.dialCode" v-model="employee_.phone" :disabled="isDisable" :onlyCountries="['US','PK']" :defaultCountry="employee_.dialCode ? employee_.dialCode:1" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
                                    <!-- <input v-model="employee_.phone" :class="[v$.employee_.phone.$error ? 'border-danger': '']" :disabled="isDisable" type="text" class="form-control" placeholder="Phone" /> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label >Gender</label>
                                    <select class="form-control" v-model="employee_.gender" :class="[v$.employee_.gender.$error ? 'border-danger': '']" :disabled="isDisable">
                                        <option value="">Choose gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label >Address</label>
                                    <input type="text" autoComplete="off" class="form-control" v-model="employee_.address.complete_address" placeholder="Enter your full business address" :disabled="isDisable"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label>Profession</label>
                                    <select v-model="employee_.profession" :class="[v$.employee_.profession.$error ? 'border-danger': '']" class="form-control">
                                        <option value="">Choose profession</option>
                                        <option v-for="profession in professions" :key="profession.id" :value="profession.id">{{profession.title}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Service render location</label>
                                    <select v-model="employee_.render_location" :class="[v$.employee_.render_location.$error ? 'border-danger': '']" class="form-control">
                                        <option value="1">Business Location</option>
                                        <option value="2">Client Location</option>
                                        <option value="3">Both Business & Client Locations</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Role</label>
                                    <select class="form-control" v-model="employee_.role" :class="[v$.employee_.role.$error ? 'border-danger': '']" >
                                        <option value="1">Employee</option>
                                        <option value="2">Manager</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- :class="[v$.employee_.licence.$error ? 'border-danger': '']"  //geting eor  -->
                        <div class="form-row">
                            <div class="form-group col-sm-6 ">
                                <div class="form-group">
                                    <label>Licence No:</label>
                                    <input type="text" class="form-control" v-model="employee_.licence"  placeholder="Enter your business licence number" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Licence State</label>
                                    <select class="form-control" v-model="employee_.licence_state" :class="[v$.employee_.licence_state.$error ? 'border-danger': '']" :disabled="employee_.licence==''">
                                        <option value="">Choose state</option>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <div class="form-group">
                                    <label>Which product this employee will be use</label>
                                    <select class="form-control" v-model="employee_.product" :class="[v$.employee_.product.$error ? 'border-danger': '']">
                                        <!-- <option value="">Choose product</option> -->
                                        <option value="2">Only Booking</option>
                                        <option value="1">Only Queue</option>
                                        <option value="3">Both Booking & Queuing </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <h3>
                                    <label class="switch">
                                        <input type="checkbox" v-model="employee_.status">
                                        <span class="slider round"></span>
                                    </label>
                                    Activate Employee
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="email-step2" v-show="step == 2 ? 'active':''">
                <div class="email-deatils">
                    <h3>Assign Services</h3>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-3" v-for="(service,index) in employee_.services" :key="index">
                            <label class="con-company" v-bind:class="service.selected ? 'currnt':''">
                                {{service.title}}
                                <input type="checkbox" v-model="service.selected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="email-step3" v-show="step == 3 ? 'active':''">
                <div class="email-deatils">
                    <h3>Weekly Schedule</h3>
                    <div class="working-hours" v-if="employee_.schedule.length > 0">        
                        <div class="day-stripe">
                            <div class="day-hours-heading">
                                Day
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Open
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Close
                                </div>
                            </div>
                            <div class="hours-open-div break-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper text-center">
                                    Break
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Break Start
                                </div>
                            </div>
                            <div class="close-hr hours-open-div">
                                <div class="day-hour-time-heading hours-open-div-wrapper ">
                                    Break End
                                </div>
                            </div>
                        </div>
                        <div class="day-stripe" v-for="(schedule,index) in employee_.schedule" :key="index">
                            <div class="day-hours-div">
                                <label class="switch">
                                    <input type="checkbox" v-model="schedule.isOpen" true-value="1" false-value="0">
                                    <span class="slider round"></span>
                                </label>
                                <span class="ml-2">{{schedule.title}}</span>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="start_time" v-model="schedule.start_time" :disabled="schedule.isOpen == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="end_time" v-model="schedule.end_time" :disabled="schedule.isOpen == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="break-div-wrapper">
                                    <label class="switch">
                                        <input type="checkbox" v-model="schedule.isBreak" true-value="1" false-value="0">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="break_start" v-model="schedule.breakStart" :disabled="schedule.isBreak == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="close-hr hours-open-div">
                                <div class="hours-open-div-wrapper pull-left">
                                    <input :class="'form-control timepicker'+schedule.day" :data-key="index" data-field="break_end" v-model="schedule.breakEnd" :disabled="schedule.isBreak == 0" placeholder="hh:mm a">
                                </div>
                            </div>
                            <div class="pull-right time-error">
                                <span v-show="schedule.hasError">{{schedule.errorMsg}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <div class="pt-4" style="border-top: 1px solid #D5D8DF;">
                        <router-link :to="{name:'Employees'}" class="add-btn float-left pr-5 pl-5">Cancel</router-link>
                        <a class="rem-btn float-right pr-5 pl-5" href="#" v-on:click="updateEmployee">
                            <span v-if="step < 3">next</span><span v-if="step == 3">Update</span>
                        </a>
                        <a class="add-btn float-right pr-5 pl-5 mr-3" v-bind:class="step == 1 ? 'd-none' : ''" href="#" v-on:click="step = step-1">Back</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// import VueTimepicker from 'vue3-timepicker'
// import 'vue3-timepicker/dist/VueTimepicker.css'
import useVuelidate from '@vuelidate/core'
import { required,email,requiredIf } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
import axios from "axios";

import $ from 'jquery';
import "jquery-datetimepicker/jquery.datetimepicker.js";
import "jquery-datetimepicker/jquery.datetimepicker.css";
import moment from 'moment'

export default {
    name: "EditEmployee",
    components: {
        VueTelInput,
        //VueTimepicker
    },
    directives: {mask},
    setup () {
        const toast = useToast();
        return { v$: useVuelidate(),toast }
    },
    data() {
        return {
            step: 1,
            //pathArr:"/",
            isDisable:true,
            employee_:{
                id:'',
                user_id:'',
                email:'',
                first_name:'',
                last_name:'',
                address:{
                    complete_address:'',
                    lat:'',
                    lng:'',
                    country:'',
                    state:'',
                    city:'',
                    street:'',
                    zip:''
                },
                phone:'',
                dialCode: null,
                gender:'',
                profession:'',
                render_location:1,
                role:1,
                services:[],
                licence:'',
                licence_state:'',
                product:2,
                schedule:[],
                status:true,
                business_id:this.$storage.getStorageSync('business').active_business.id
            },
            //employeeSchedule:[],
            errorMsg : false,
            states:[],
            professions:[],
            //days:["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            selected_services:[],
            user_role: "",
        }
    },
    validations () {
        return {
            employee_:{
                email:{ required,email },
                first_name:{ required },
                last_name:{ required },
                gender:{ required },
                profession:{ required },
                render_location:{ required },
                role:{ required },
                product:{ required },
                // licence:{ required },
                licence_state:{
                    requiredIfFoo: requiredIf(this.employee_.licence),
                }
            }
        }
    },
    created() {
        //this.pathArr = this.$route.path.split("/");
        //this.getemployeeDetail()
        this.getStates()
        this.getProfessions()
        this.getServices()

        window.jQuery.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            formatDate: function (date, format) {
                return moment(date).format(format);
            }
        });
    },
    methods: {
        async getemployeeDetail(){
            let thiss = this
            await axios.get('employee_detail/'+this.$route.params.id+'/'+this.$storage.getStorageSync('business').active_business.id)
            .then(function (response) {
                //console.log(response.data.data.schedule)
                if (response.data.status == 'success') {
                    thiss.employee_.schedule = response.data.data.schedule
                    console.log(thiss.employee_.schedule)
                    setTimeout(function() {
                        thiss.employee_.schedule.forEach((item) => {
                            $('.timepicker'+item.day).datetimepicker({
                                datepicker:false,
                                format:'h:mm A',
                                formatTime:'h:mm A',
                                todayButton: false,
                                allowTimes:item.active_hours,
                                onChangeDateTime:function(dp,$input){
                                    console.log(dp);
                                    thiss.employee_.schedule[$input.data('key')][$input.data('field')] = $input.val()
                                }
                            });
                        });
                    }, 500);

                    var employee = response.data.data.employee
                    var services = response.data.data.services
                    thiss.employee_.id = employee.id
                    thiss.employee_.user_id = employee.user_id
                    thiss.employee_.first_name = employee.user.first_name
                    thiss.employee_.last_name = employee.user.last_name
                    thiss.employee_.email = employee.user.email
                    thiss.employee_.dialCode = parseInt(employee.user.phone.slice(0, -10))
                    thiss.employee_.phone = employee.user.phone.slice(-10)
                    thiss.employee_.gender = employee.user.gender
                    thiss.employee_.profession = employee.profession_id
                    thiss.employee_.render_location = employee.service_rendered
                    // if (employee.service_rendered == 1) {
                    //     thiss.employee_.render_location = 'business'
                    // }else{
                    //     thiss.employee_.render_location = 'client'
                    // }
                    thiss.employee_.role = employee.role
                    thiss.employee_.licence = employee.licence ? employee.licence:''
                    thiss.employee_.licence_state = employee.licence_state ? employee.licence_state:''
                    thiss.employee_.role = employee.role
                    console.log(employee.product)
                    thiss.employee_.product = employee.product
                    if (employee.status == 1) {
                        thiss.employee_.status = true
                    }else{
                        thiss.employee_.status = false
                    }
                    thiss.employee_.address.complete_address = employee.user.address
                    thiss.employee_.address.lat = employee.user.lat
                    thiss.employee_.address.lng = employee.user.lng
                    thiss.employee_.address.country = employee.user.country
                    thiss.employee_.address.state = employee.user.state
                    thiss.employee_.address.city = employee.user.city
                    thiss.employee_.address.street = employee.user.street
                    thiss.employee_.address.zip = employee.user.zip
                    
                    //console.log(thiss.employee_)
                    if (services.length > 0) {
                        services.forEach((serv) => {
                            thiss.selected_services.push(serv.business_service.id)
                        });
                    }
                }else{
                    thiss.employee_.dialCode = 1
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
                thiss.employee_.dialCode = 1
            });
        },

        async updateEmployee() {
            if (this.step == 1) {
                this.v$.employee_.$touch()
                //if (!this.v$.employee_.first_name.$error && !this.v$.employee_.last_name.$error && !this.v$.employee_.email.$error && !this.v$.employee_.phone.$error && !this.v$.employee_.gender.$error && !this.v$.employee_.render_location.$error && !this.v$.employee_.role.$error && !this.v$.employee_.profession.$error) {
                if (!this.v$.employee_.$error){
                    this.step++
                }
            }else if (this.step == 2) {
                this.step++
            }else if (this.step == 3) {
                let hasError = false
                await this.employee_.schedule.forEach((value,index) => {
                    if (value.isOpen == 1) {
                        var open = value.start_time;
                        var close = value.end_time;
                        var stt = new Date("November 13, 2019 " + open);
                        stt = stt.getTime();
                        var endt = new Date("November 13, 2019 " + close);
                        endt = endt.getTime();
                        if(stt >= endt) {
                            this.employee_.schedule[index].hasError = true
                            this.employee_.schedule[index].errorMsg = "Close time must be greater then Open time."
                            hasError = true
                        }else{
                            if (value.isBreak == 1) {
                                var bopen = value.breakStart;
                                var bclose = value.breakEnd;
                                var bst = new Date("November 13, 2019 " + bopen);
                                bst = bst.getTime();
                                var bend = new Date("November 13, 2019 " + bclose);
                                bend = bend.getTime();
                                if(bst >= bend) {
                                    this.employee_.schedule[index].hasError = true
                                    this.employee_.schedule[index].errorMsg = "Break End time must be greater then Break Start time."
                                    hasError = true
                                }else{
                                    this.employee_.schedule[index].hasError = false
                                }
                            }else{
                                this.employee_.schedule[index].hasError = false
                            }
                        }
                    }
                });
                //console.log(this.employee_)
                if (hasError === false) {
                    let thiss = this
                    axios.post('edit_employee',this.employee_).then(function (response) {
                        if (response.data.status == 'success') {
                            thiss.$router.push({ name: 'Employees'})
                           
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            }
        },
        getServices(){
            let thiss = this
            axios.get('businessServices').then(function (response) {
                if (response.data.status == 'success') {
                    var data = []
                    response.data.data.forEach((serv) => {
                        if(thiss.selected_services.indexOf(serv.id) !== -1){
                            data.push({ si:serv.service_id, bsi:serv.id, title:serv.service.title, selected:true})
                        }else{
                            data.push({ si:serv.service_id, bsi:serv.id, title:serv.service.title, selected:false})
                        }
                    });
                    thiss.employee_.services = data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        getStates(){
            let thiss = this
            axios.get('states').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.states = response.data.data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        getProfessions(){
            let thiss = this
            axios.get('professions').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.professions = response.data.data
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        // updateBreakHoursRange(resp,index){
        //     if (this.employee_.schedule[index].start_time.HH != '' && this.employee_.schedule[index].start_time.HH != null && this.employee_.schedule[index].start_time.HH != undefined && this.employee_.schedule[index].start_time.HH != 'undefined' && this.employee_.schedule[index].end_time.HH != '' && this.employee_.schedule[index].end_time.HH != null && this.employee_.schedule[index].end_time.HH != undefined && this.employee_.schedule[index].end_time.HH != 'undefined') {
        //         if (this.employee_.schedule[index].end_time.HH > this.employee_.schedule[index].start_time.HH) {
        //             this.employee_.schedule[index].breakHoursRange = [[this.employee_.schedule[index].start_time.HH,this.employee_.schedule[index].end_time.HH]]
        //         }
        //     }
        // }
    },
    mounted() {
        this.user_role = this.$storage.getStorageSync('role')
        if(this.user_role == 'professional'){
            this.isDisable = false;
        }
        this.getemployeeDetail()
        // Configer Get Data From Autocomplete
        // let thiss = this
        // const autocomplete = new window.google.maps.places.Autocomplete(this.$refs["origin"],{componentRestrictions:{country:['US','CA']}});
        // autocomplete.addListener('place_changed', function () {
        //     const place = autocomplete.getPlace();
        //     // Get Complete Address Array
        //     thiss.getAddressArray(place)
        // });
    }
};
</script>
<style scoped>
    .email-search input{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .email-search a{
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .working-hours{
        margin-bottom: 15px;
        padding: 0;
    }
    .time-picker-overlay{
        outline: none;
    }

    .day-stripe{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-right:15px;padding-left:15px;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-flex-align:stretch;align-items:stretch;border-bottom:1px solid rgba(0,0,0,.1);-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}

    .day-hours-div,.day-hours-heading {
        width: 19%;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-right: 1px solid rgba(0, 0, 0, .1);
        font-size: 16px
    }
    .day-hours-div{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .day-hours-heading,.day-hour-time-heading{font-weight: 800;}
    .day-hour-time-heading{
        padding: 10px 18px !important;
    }
    .day-hours-div label.vue-js-switch{
        margin-right: 15px;
    }
    .day-hours-div .v-switch-label{
        text-transform: uppercase;
    }
    .hours-open-div {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 18%;
        border-right: 1px solid rgba(0, 0, 0, .1)
    }
    .break-div{
        width: 8% !important;
    }

    .hours-open-div.close-hr {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: block;
        border-right-style: none
    }
    .hours-open-div-wrapper{
        padding: 10px 12px;
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .break-div-wrapper{
        padding: 15px;
        position: relative;
        display: block;
        margin-bottom: 0;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .time-error{
        color: #eb6663;
        padding: 0;
        width: 100%;
    }
</style>
<style>
    .vue__time-picker, .vue__time-picker input.vue__time-picker-input{
        width: 100% !important; 
        max-width: 120px;
    }

    .xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover{
        background:#6e317a !important
    }
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current{
        background: #6e317a;
        box-shadow: #6e317a 0 1px 3px 0 inset;
    }
</style>
<style src="@vueform/toggle/themes/default.css"></style>