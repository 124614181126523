<template>
    <AppContainer>
        <EditEmployee/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import EditEmployee from '@/components/dashboard/employees/EditEmployee.vue'
export default {
  components: {
    AppContainer,
    EditEmployee
  }
}
</script>